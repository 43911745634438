









import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    components: {}
})
export default class PhoneWts extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value: string

    get phone852() {
        if(!this.value) return ''
        let p = this.value.startsWith('+') ? this.value.replace('+', '') : this.value
        return (p.startsWith('852') && p.length === 3 + 8) ? p : '852' + p
    }
}
